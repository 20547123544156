import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_event_card = _resolveComponent("event-card")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, {
    title: "Find Event",
    "back-link": "true"
  }, {
    default: _withCtx(() => [
      ($data.loading)
        ? (_openBlock(), _createBlock(_component_ion_loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        "pull-factor": "0.5",
        "pull-min": "100",
        "pull-max": "150",
        onIonRefresh: $options.refreshData
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content, {
            "pulling-icon": $setup.refresh,
            refreshingSpinner: "crescent"
          }, null, 8, ["pulling-icon"])
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.events, (event) => {
        return (_openBlock(), _createBlock(_component_event_card, {
          key: event.id,
          event: event
        }, null, 8, ["event"]))
      }), 128))
    ]),
    _: 1
  }))
}