
import { IonRefresher,  IonRefresherContent, IonLoading } from '@ionic/vue';
import { refresh } from 'ionicons/icons';
import EventCard from "@/components/card/EventCard.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import EventServices from "@/services/event.services";

export default {
  name: 'FindEvent',
  components:{
    BaseLayout,
    EventCard,
    IonRefresher,
    IonRefresherContent,
    IonLoading
  },
 setup(){
   return {
     refresh
   }
 },
  created(){
    this.loadEvents();
  },
  methods:{
    loadEvents(){
      this.loading =true;
      EventServices.getAllEvent().then(
         res =>{
           this.events = res;
           this.loading = false;
           this.error= false;

         },
          err =>{
           console.log(err);
           this.error= true;
           this.loading = false;
          }
      )
       // this.$store.dispatch('getAllEvents');
    },
    refreshData($event){
      EventServices.getAllEvent().then(
          res =>{
            console.log(res);
            this.events = res;
            this.loading = false;
            this.error= false;
            $event.target.complete();
          },
          err =>{
            console.log(err);
            this.error= true;
            this.loading = false;
          }
      )
    }
  },
  watcher:{
    events(){
      return this.events;
    }
  },
  data(){
    return{
      loading: false,
      events: [],
      error: false
    }
  }
}

